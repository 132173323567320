import { ContentfulService } from '../contentful.service';
import { Component, OnInit } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { DomSanitizer,Meta ,Title } from '@angular/platform-browser';

import { bundle } from '../bundle';


@Component({
  selector: 'app-case-study',
  templateUrl: './case-study.component.html',
  styles: []
})
export class CaseStudyComponent implements OnInit {

  caseStudie:any;


  constructor(private service : ContentfulService,private sanitizer: DomSanitizer,private metaService:Meta , private titleService:Title ) { }

  
  ngOnInit() {
    this.caseStudie = this.service.getEntry("GAOM2AL6aOiM8we6M8oO6",{'locale':this.service.lang}).then(res=>{
     // console.log(res.fields)

      var items = []
      res.fields['caseStudyItem'].forEach(element => {
        if(element.fields){
          element.fields['caseContentHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(element.fields.caseContent))
          items.push(element.fields)
        }

      });
      // console.log(items)

      this.service.hideLoader();
      return items
    })
    var componentName = this.constructor.name;
    var seo = bundle[componentName]
    if(seo){
        this.titleService.setTitle(seo[this.service.lang].title)
        this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
        this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
    }
  }





}
