import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ContentfulService, } from '../contentful.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})



export class FooterComponent implements OnInit {

  constructor(private service: ContentfulService, private route: Router) { }

  dateObj = new Date();
  get lang(): string {
    return this.service.lang
  }

  get otherTreatment(): any {
    return this.service.otherTreatment
  }

  ngOnInit() {
  }



  gotoPage(routeDesc: string) {
    this.route.navigate([this.lang, routeDesc], { fragment: 'contact-form' });
  }

  // no use, added button to the box

}
