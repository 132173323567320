import { bundle } from './../bundle';

import { Pipe, PipeTransform } from '@angular/core';
import { ContentfulService } from '../contentful.service';


@Pipe({
    name: 'i18nCf'
  })

  export class I18nContentfulPipe implements PipeTransform {
    constructor(private service:ContentfulService) { }


    transform(value: string, args?: Array<string>): any {
      var bundles  = this.service.contentBundle
      var bundle  = bundles.find(e => { return e.key === value })
      var ret = "*** " + value + " ***"
      
      if(bundle){
        ret = bundle.value !== undefined ? bundle.value : ret
      }
      return ret
    }


    


  }