import { LoaderService } from './loader.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from "@angular/router";
import { ContentfulService } from './contentful.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AnimationGuard implements CanActivate {

    private hasAnimation:boolean = true;

     constructor(private service:ContentfulService){
     }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //this.loaderService.show()
      //  return Promise.resolve(true)
     


      if(this.service.isBrowser){
            return new Promise<Boolean>((resolve, reject) => {
                setTimeout(() => {
                    resolve()
                }, 1000)
            }).then(result => {
                return true;
            });
        }else{
            return Promise.resolve(true)
        }
    }
  }