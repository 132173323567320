import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulService, BUNDLE_KEY } from './contentful.service';
import { Component, OnInit, HostListener, Injector, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';


@Component({
    selector: 'app-base',
    template: `
    <app-header [class.d-none]="!isBrowser" [ngClass]="{'isCht': this.isChinese()}" id="header" ></app-header>
    <div [class.d-none]="!isBrowser" class="body-padding" [ngClass]="{'isCht': this.isChinese()}">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    </div>
    <app-footer [class.d-none]="!isBrowser" [ngClass]="{'isCht': this.isChinese()}"></app-footer>
    <div [class.d-none]="!isBrowser" [ngClass]="{'show': this.backToTop}" class="back-to-top" [ngxScrollTo]="'header'" ><i class="fas fa-chevron-up"></i></div>`,
    styles: []
})


@HostListener('window:resize', ['$event'])


export class BaseComponent implements OnInit {
    backToTop: Boolean = false;
    langSnapshot: String = "";
    isBrowser: boolean;


    constructor(protected router: Router, protected activatedRouter: ActivatedRoute, protected service: ContentfulService, private injector: Injector,@Inject(PLATFORM_ID) private platformId ,@Inject(DOCUMENT) private _document: any,private metaService:Meta , private titleService:Title ) { 
        this.isBrowser = isPlatformBrowser(platformId);
    }

    get lang(): string {
        return this.service.lang
    }

    ngOnInit() {

        var locale = this.activatedRouter.snapshot.paramMap.get('lang')
        //  console.log(locale,this)
        if (!BUNDLE_KEY.includes(locale)) {
            this.router.navigate([this.service.lang, 'home'])
        }else{
            this.service.lang = locale
            this.langSnapshot = locale
            this._document.documentElement.lang = this.service.lang

        }


//console.log(this.activatedRouter)
    }

    public onRouterOutletActivate(event : any) {
        // var componentName = event.constructor.name;

        // var seo = bundle[componentName]

        // if(seo){
        //     this.titleService.setTitle(seo[this.lang].title)
        //     this.metaService.updateTag({property:"og:title" , content:seo[this.lang].title})
        //     this.metaService.updateTag({property:"og:description" , content:seo[this.lang].description})
        //     this.metaService.updateTag({name:"description" , content:seo[this.lang].description})
        //     this.metaService.updateTag({name:"keyword" , content:seo[this.lang].keyword})
        // }


    }

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.backToTop = (window.pageYOffset > 800)
    }

    isChinese():Boolean{
        return this.langSnapshot == 'zh-Hant';
    }

}