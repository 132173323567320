

export const bundle = {
    HomeComponent: {
        "zh-Hant": {
            title: "FH Remodel 紮身護理專門店",
            description: "FH Remodel專業而到位的紮身技術，能令產後媽媽的身型於最短時間內回復美麗健康，身段甚至比產前更迷人。",
            keyword: "紮身, 紮肚, 扎身, 扎肚, 紥身, 紥肚"
        },
        "en-US": {
            title: "FH Remodel - Professional binding service to postpartum women",
            description: "FH Remodel uses accurate abdominal binding technique to help new moms speed up their recovery and regain their pre-pregnancy bodies and confidence.",
            keyword: "紮身, 紮肚, 扎身, 扎肚, 紥身, 紥肚"
        }
    },
    TreatmentComponent: {
        "zh-Hant": {
            title: "FH Remodel - 塑身療程 | 產後問題及紮身原理",
            description: "產後媽媽除了面對產後身體變得肥腫的同時，更影響深遠的，是腹直肌分離、內臟移位、胸骨及盆骨增寛等結構性問題。FH Remodel專業而到位的紮身技術，能令產後媽媽的身型於最短時間內回復美麗健康，身段甚至比產前更迷人。",
            keyword: "紮身"
        },
        "en-US": {
            title: "FH Remodel - Treatments | Binding Methodology and Common Problems Faced by Mothers",
            description: "After giving birth, not only will the new mom’s outward appearance change, she may also suffer from weakened or stretched muscles, leading to conditions such as diastasis recti (a gap between the abdominal muscles), internal organ prolapse, rib flare or a widening pelvis. FH Remodel, and a postpartum binding professional, uses her accurate abdominal binding technique to help new moms speed up their recovery and regain their pre-pregnancy bodies and confidence.",
            keyword: "紮身"
        }
    },

    TreatmentMainComponent: {
        "zh-Hant": {
            title: "FH Remodel - 塑身療程 | 紮身療程",
            description: "揉合印尼、馬來西亞、新加坡、泰國等地的束肚方法，設計出一種適合香港繁忙都市婦女的力度和紮身療程 ，幫助媽媽回復線條，重拾自信。",
            keyword: "紮身"
        },
        "en-US": {
            title: "FH Remodel - Treatments | Remodel Binding",
            description: "With the consolidation of tummy binding techniques from Indonesia, Malaysia, Singapore, Thailand, etc., FH Remodel works out a program which is suitable for women in bustling city, like Hong Kong, to assist postpartum mamas be slimmer, with more contoured waistline and higher self-esteem",
            keyword: "紮身"
        }
    },

    "3vjBiITKdWUockegCygCAO": {
        "zh-Hant": {
            title: "FH Remodel - 塑身療程 | 完美新娘療程",
            keyword: "新娘",
            description: "一生一次成為衆人拍攝的主角，每位新娘都希望能以完美體態顯現於人前。完美新娘療程可於短期內助妳纖體成功，讓身形曲綫更立體，盡顯體態美。"
        },
        "en-US": {
            title: "FH Remodel - Treatments | Bridal Treatment",
            description: "Every girl wants to be perfect in her wedding. FH Remodel has \"Bridal Treatment\" to help you achieve that! No exercise nor medicine, we help you to have curvaceous body line and be perfect at your wedding! ",
            keyword: "新娘"
        }
    },
    "43KsjeZaG4uiOsgIk8AQYk": {
        "zh-Hant": {
            title: "FH Remodel - 塑身療程 | 纖手臂療程",
            keyword: "手臂",
            description: "纖手臂療程以深層按摩配合棉布打圈束紮上手臂，每圈發揮適當拉力，能將脂肪細胞壓細修形，且無礙日常活動。"
        },
        "en-US": {
            title: "FH Remodel - Treatments | Arms Slimming Treatment",
            description: "FH Remodel therapists use deep tissue massage to remodel the shape.",
            keyword: "手臂",
        }

    },
    "4XmfMHSqRGigAcmuIIwCOY": {
        "zh-Hant": {
            title: "FH Remodel - 塑身療程 | 胸部再生療程",
            keyword: "胸部療程",
            description: "胸部再生療程針對背部和手臂進行按摩，疏通經絡，除了令胸部緊緻提升，還可改善副乳問題。"
        },
        "en-US": {
            title: "Treatments | Reignite Bust Treatment",
            keyword: "胸部療程",
            description: "FH Remodel provides the best solution to the mamas."
        }
    },

    AboutUsComponent:{
        "zh-Hant": {
            title: "FH Remodel - 關於我們",
            keyword: "",
            description: "FH Remodel專業而到位的紮身技術，能令產後媽媽的身型於最短時間內回復美麗健康，身段甚至比產前更迷人。"
        },
        "en-US": {
            title: "FH Remodel - About Us",
            keyword: "",
            description: "FH Remodel uses accurate abdominal binding technique to help new moms speed up their recovery and regain their pre-pregnancy bodies and confidence."
        }
    },
    CaseStudyComponent:{
        "zh-Hant": {
            title: "FH Remodel - 個案分享",
            keyword: "",
            description: "FH Remodel在短短幾年間協助多名媽媽塑身，健康成功例子有目共睹。幾位既是媽媽，亦是不同行業的專業女性，分享親身經歷，讓讀者明白紥身的效果。"
        },
        "en-US": {
            title: "FH Remodel - Case Studies",
            keyword: "",
            description: "FH Remodel Helps You Back to Shape"
        }
    },
    MediaComponent:{
        "zh-Hant": {
            title: "FH Remodel - 媒體報導",
            keyword: "",
            description: "FH Remodel在短短幾年間協助多名媽媽塑身，健康成功例子有目共睹。幾位既是媽媽，亦是不同行業的專業女性，分享親身經歷，讓讀者明白紥身的效果。"
        },
        "en-US": {
            title: "FH Remodel - Media",
            keyword: "",
            description: "FH Remodel Helps You Back to Shape"
        }
    },
    SpecialOfferComponent:{
        "zh-Hant": {
            title: "FH Remodel - 最新優惠",
            keyword: "",
            description: "瀏覽FH Remodel所有最新優惠!"
        },
        "en-US": {
            title: "FH Remodel - Special Offer",
            keyword: "",
            description: "Find the latest offers from FH Remodel!"
        }
    },
    BlogComponent:{
        "zh-Hant": {
            title: "FH Remodel - FH網誌",
            keyword: "",
            description: "瀏覽FH Remodel所有最新資訊!"
        },
        "en-US": {
            title: "FH Remodel - FH Blog",
            keyword: "",
            description: "Find the latest information from FH Remodel!"
        }
    },
    ContactUsComponent:{
        "zh-Hant": {
            title: "FH Remodel - 聯絡我們",
            keyword: "",
            description: "歡迎您來免費諮詢，為給您最好服務安排"
        },
        "en-US": {
            title: "FH Remodel - Contact Us",
            keyword: "",
            description: "We welcome for Free Consultation to offer the most suitable program for you"
        }
    }


    






}

