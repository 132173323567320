import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {BLOCKS} from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => 
      `<img src='http:${node.data.target.fields.file.url}' > `
  }
}



@Component({
  selector: 'price-table',
  templateUrl: './price-table.component.html',
  styles: []
})
export class PriceTableComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }

  @Input() price : any;
  
  ngOnInit() {
    // console.log(this.price)
    this.price['priceContentHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(  this.price.priceContent , options ))
    this.price['descriptionHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(this.price.description))
    // console.log(this.price)
  }

}
