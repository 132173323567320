import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulService } from '../contentful.service';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { LoaderService } from './../loader.service';

import { bundle } from '../bundle';

@Component({
  selector: 'app-treatment-other',
  templateUrl: './treatment-other.component.html',
  styles: []
})
export class TreatmentOtherComponent implements OnInit {

  constructor(private router : Router, private activedRoute:ActivatedRoute , private service:ContentfulService,private sanitizer: DomSanitizer,private loaderService: LoaderService , private metaService:Meta , private titleService:Title) { }


  get otherTreatment():any{
    return this.service.otherTreatment
  }
  treatment = null

  priceTable = null




  ngOnInit() {

    //console.log(this.otherTreatment2)
    this.activedRoute.params.subscribe(params =>{
      var id = params['id']
      this.loadOtherTreatment(id)


      var seo = bundle[this.treatment.id]
      
      if(seo){
        
        this.titleService.setTitle(seo[this.service.lang].title)
            this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
            this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
            this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
            this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
      }
    })
    
    
  }


  loadOtherTreatment(id:string){

    this.treatment = this.otherTreatment.find(o => o.seoKey === id )


      // console.log(this.treatment)
    if(this.treatment==undefined ||  this.treatment == null){
      this.router.navigate([this.service.lang , "home"])
    }

    if(this.treatment.priceTable){
      var promies = []
      this.treatment .priceTable.forEach(pt => {
        promies.push(this.service.getEntry(pt.sys.id , {'locale':this.service.lang}))
      });

      this.priceTable=Promise.all(promies).then(p =>{
        var retPriceTable =[]
        p.forEach(ele =>{
          if(ele.fields){
            retPriceTable.push(ele.fields)
          }
        })
        this.loaderService.hide()
        return retPriceTable
      })
    }else{
      this.loaderService.hide()
    }


    
   
  }


  
  
}

