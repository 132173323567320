import { LoaderState } from './loader/loader';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { Component ,OnInit} from '@angular/core';
import { Router,Event,NavigationEnd , NavigationStart} from '@angular/router';
import { ContentfulService } from './contentful.service';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent implements OnInit{

  private subscription: Subscription;
  showContent = false;

  constructor(private router: Router ,private service: ContentfulService, private _scrollToService: ScrollToService, private loaderService:LoaderService) {
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        //console.log("navigation end")
        service.openCloseNav()
        this.service.hideLoader()
      }

      if(event instanceof NavigationStart) {
        //console.log("navigation start")
        this.service.showLoader()
      }
    })

    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      this.showContent = !state.show;
    });
  }

  ngOnInit() {


  }

  backToTop(){
    //console.log("scroll to top")
    //window.scrollTo(0, 0);

    const config: ScrollToConfigOptions = {
      target: 'header'
    };

    //console.log(config)
 
    this._scrollToService.scrollTo(config);


  }
}