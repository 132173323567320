export const environment = {
  production: true,
  contentful :{
    baseUrl: "https://cdn.contentful.com",
    spaceId : "fncbjpexxiug",
    token :"9e19911747f27bcb2190a5e7278d083ec5fe6c153f9d132a3dbb1afb135bb142"
  },
  localePath :{
    'en-Us' : "",
    'zh-Hant' : ""
  },
  emailUrl:'/',
  restUrl: "http://localhost:8080/rest/",
  recaptchaKey:'6Leain0UAAAAAJXAHEbw-DkGWr4zVZtIkCukbaRF'
};
