

import { Pipe, PipeTransform } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { bundle } from '../bundle';

@Pipe({
  name: 'i18n'
})
export class I18nPipe implements PipeTransform {

  constructor(private service:ContentfulService) { }

  transform(value: string, args?: Array<string>): any {
    // console.log("aaa" , value)
    var lang = this.service.lang

    var ret: string = null;

    var bu: any = bundle  

    value.split(".").forEach(element => {

      try {
        bu = bu[element];


      } catch{

      }
    });
    // console.log("aaa" , bu)
    ret = bu

    if (ret === undefined || ret === null) {
      ret = "*** " + value + " ***"
    } else {
      ret = bu[lang]
    }
    if (args) {
      args.forEach((argv, i) => {
        ret = ret.replace('{' + i + '}', argv)
      })
    }

    return ret;
  }

}
