import { MediaComponent } from './media/media.component';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,APP_INITIALIZER} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CaseStudyComponent } from './case-study/case-study.component';

import { TreatmentComponent } from './treatment/treatment.component';
import { ContentfulService } from './contentful.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18nPipe } from './pips/i18n.pipe';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { specialOfferComponent } from './specialOffer/specialOffer.component';
import { BlogComponent } from './blog/blog.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AgmCoreModule } from '@agm/core';
import { TreatmentMainComponent } from './treatment-main/treatment-main.component';
import { TreatmentOtherComponent } from './treatment-other/treatment-other.component';
import { I18nContentfulPipe } from './pips/i18n-cf.pipe';
import { PriceTableComponent } from './price-table/price-table.component';
import { BaseComponent } from './base.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { LoaderComponent } from './loader/loader.component'
import { LoaderInterceptorService } from './loader-interceptor.service';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutUsComponent,
    CaseStudyComponent,
    TreatmentComponent,
    MediaComponent,
    I18nPipe,
    I18nContentfulPipe,
    specialOfferComponent,
    BlogComponent,
    ContactUsComponent,
    TreatmentMainComponent,
    TreatmentOtherComponent,
    PriceTableComponent,
    BaseComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    NgtUniversalModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ModalModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    ScrollToModule.forRoot(),
    PopoverModule.forRoot(),
    BrowserAnimationsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    SlickCarouselModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBemHMx14oj-5jWg0Ry19-HeiVq4rqS0uo',
      language: 'zh-TW'
    }),
    NgbPaginationModule, NgbAlertModule,
    ReactiveFormsModule
  ],
  providers: [ContentfulService ,
            {provide: APP_INITIALIZER ,
             useFactory: (contentfulService:ContentfulService) => () => contentfulService.initBundle()  , deps:[ContentfulService] , multi:true   },
             {provide: APP_INITIALIZER ,
              useFactory: (contentfulService:ContentfulService) => () => contentfulService.initOtherTreatment()  , deps:[ContentfulService] , multi:true   },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: LoaderInterceptorService,
              multi: true
            },
            DatePipe]
              ,
  bootstrap: [AppComponent]
})
export class AppModule { }