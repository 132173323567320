import { ActivatedRoute } from '@angular/router';
import { Component, OnInit,TemplateRef ,ViewChild} from '@angular/core';
import { ContentfulService } from '../contentful.service';
import {BsModalService} from 'ngx-bootstrap/modal'
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import {  Meta ,Title} from '@angular/platform-browser';
import { bundle } from '../bundle';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styles: []
})
export class ContactUsComponent implements OnInit {

  
  constructor(private activeRoute:ActivatedRoute, private service : ContentfulService , private modalService: BsModalService,private formBuilder: FormBuilder, private metaService:Meta , private titleService:Title) { 
  }

  get isBrowser(){
    return this.service.isBrowser
  }
  title: string = 'FH remodel Office';
  lat: number = 22.287423;
  lng: number = 114.144746;
  zoomValue: number = 18;
  zoomControl: boolean = true;
  scrollwheel: boolean = false;
  modalRef: BsModalRef;
  emailForm:FormGroup
  submitted=false
  @ViewChild('emailSendTemplate', { static: true })  emailSendTemplate ;
  iconUrl: string = 'https://www.fhremodelhk.com/assets/images/icon_location.png';
  styles: any[] = [

    {
      elementType: "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      elementType: "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "transit.station.rail",
      "stylers": [
        {
          "visibility": "on"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }



  ]

  get f() { return this.emailForm.controls; }

  get siteKey() {
    return environment.recaptchaKey
  }

  ngOnInit() {

    if(this.service.isBrowser){
      this.service.hideLoader();
      this.activeRoute.fragment.subscribe(f => {
        const element = document.querySelector("#" + f)
        if (element) element.scrollIntoView()
      })
    }


   this.emailForm = this.formBuilder.group({
     name:['', Validators.required],
     mobile:['',Validators.required],
     email:['', Validators.email ],
     remark:['',]  ,
     captcha:['' ,Validators.required]} )


     var componentName = this.constructor.name;
     var seo = bundle[componentName]
     if(seo){
         this.titleService.setTitle(seo[this.service.lang].title)
         this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
         this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
         this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
         this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
     }
  }

  isOpen: boolean = false;

  public markerClick(e) {
    console.log(e);
    e.open();
    this.isOpen = true;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.emailForm.invalid) {
        return;
    }else{
       console.log(this.emailForm.value)
      this.service.sendEmail(this.emailForm.value).then(res=>{
        this.modalRef  = this.modalService.show(this.emailSendTemplate)
        this.emailForm.reset()
        this.submitted= false
      }).catch(err =>{
        console.log(err)
      })
    }

    
    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.emailForm.value))
}


}
