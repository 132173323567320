import { ContentfulService } from './../contentful.service';
import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { LoaderService } from './../loader.service';
import {  Meta ,Title} from '@angular/platform-browser';
import { bundle } from '../bundle';
@Component({
  selector: 'app-treatment',
  templateUrl: './treatment.component.html',
  styles: []
})
export class TreatmentComponent implements OnInit {
  get lang():string{
    return this.service.lang
  }
  
  get otherTreatment():any{
    return this.service.otherTreatment
  }
  constructor(private activeRoute:ActivatedRoute, private service:ContentfulService , private route:Router,private loaderService: LoaderService, private metaService:Meta , private titleService:Title ) { }

  stepHide = [false,true,true]

    faqs = null;

  ngOnInit() {
    this.faqs = this.getFaq()
    this.activeRoute.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      if (element) element.scrollIntoView()
    })
    // console.log(this.otherTreatment)
    var componentName = this.constructor.name;
    var seo = bundle[componentName]
    if(seo){
        this.titleService.setTitle(seo[this.service.lang].title)
        this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
        this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
    }
  }

  stepClick(idx){
    

    this.stepHide.forEach( (ele, idx, theArray) =>{ 
      theArray[idx]= true
      })

    this.stepHide[idx] = false
  }

  gotoMainTreatment(){
    this.route.navigate([this.lang,'treatment','remodel-binding'])
  }


  gotoOtherTreatment(id:string){
    this.route.navigate([this.lang,'treatment',id])
  }
  
  getFaq(){
    let faqid ="34HyqCSqgMeUW8uGu2KOW6"
    return this.service.getEntry( faqid , {locale: this.service.lang }).then(res =>{
            var faqArray =[]
            res.fields['faqItem'].forEach( (entry ) =>{
               // console.log(idx)
               if(entry.fields){
                entry.fields['expand'] = false
                faqArray.push(entry.fields)

               }
            //    console.log(entry.fields)
            })
            //console.log(faqArray)
            this.loaderService.hide()
            return faqArray
        })
        
    
}

}
