import { Meta, Title } from '@angular/platform-browser';
import { ContentfulService } from '../contentful.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { bundle } from '../bundle';



@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styles: []
})

export class AboutUsComponent implements OnInit {

  constructor(private service: ContentfulService, private metaService: Meta, private http: HttpClient, private titleService: Title) { }

  get lang(): string {
    return this.service.lang
  }


  public users: any = [];
  dummyDatas = [];

  @ViewChild('quoteMessage', { static: true }) quoteMessage;

  ngOnInit() {
    
    this.service.hideLoader();
    var componentName = this.constructor.name;
    var seo = bundle[componentName]
    if(seo){
        this.titleService.setTitle(seo[this.service.lang].title)
        this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
        this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
    }
    
  }

}
