import { LoaderService } from './loader.service';
import { Injectable, EventEmitter, Injector, PLATFORM_ID, Inject } from '@angular/core';
import { environment } from '../environments/environment';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { createClient, Entry } from 'contentful'
import { isPlatformBrowser } from '@angular/common';
import { BLOCKS } from '@contentful/rich-text-types';

const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) =>
        `<img src='http:${node.data.target.fields.file.url}' > `
    }
  }
  

export const BUNDLE_KEY = ['zh-Hant', 'en-US']
export const BUNDLE_HASH = { 'zh-Hant': 0, 'en-US': 1 }

export interface ContentBundle {
    key: string
    value: string

}

@Injectable()

export class ContentfulService {

    private _lang: string

    private _contentBundle = []
    private _otherTreatment = []
    isBrowser: boolean;
    

    get otherTreatment() {
        return this._otherTreatment[BUNDLE_HASH[this.lang]]
    }
    get otherTreatmentIndex(){
        return this._otherTreatment['index']
    }
    get lang(): string {
        // console.log(this._lang )

        if (this._lang) {
            return this._lang
        } else {
            if (this.isBrowser) {
                var locale = localStorage.getItem('locale')
                if (locale) {
                    this.lang = locale
                } else {
                    this.lang = BUNDLE_KEY[0]
                }
                return locale
            } else {
                return BUNDLE_KEY[0]
            }
        }

    }

    set lang(lang: string) {

        if (BUNDLE_KEY.includes(lang)) {
            if (this.isBrowser) {
                localStorage.setItem('locale', lang)
            }
            this._lang = lang
        }


    }

    get contentBundle() {
        return this._contentBundle[BUNDLE_HASH[this.lang]]
    }

    public showLoader() {
        this.loaderService.show()
    }

    public hideLoader() {
        this.loaderService.hide();
        // setTimeout(() => {
        // this.loaderService.hide();
        // }, 500)
    }

    public contentfulClient = createClient({
        space: environment.contentful.spaceId,
        accessToken: environment.contentful.token
    });

    public get router(): Router { //this creates router property on your service.
        return this.injector.get(Router);
    }


    navEmitter = new EventEmitter<boolean>();



    constructor(
        private http: HttpClient, private injector: Injector, @Inject(PLATFORM_ID) private platformId, private loaderService: LoaderService, private sanitizer: DomSanitizer) {
        this.isBrowser = isPlatformBrowser(platformId);
    }


    openCloseNav(openClose: boolean = false) {

        this.navEmitter.emit(openClose)
    }

    initBundle(): Promise<any> {
        return new Promise((resolve, reject) => {
            var promises = []
            BUNDLE_KEY.forEach(b => {
                promises.push(this.contentfulClient.getEntries({ content_type: "bundle", 'locale': b, limit: 1000 }))
            })
            Promise.all(promises).then(responses => {

                responses.forEach((res, idx) => {
                    let bundles = res.items;
                    var retBundles = new Array<ContentBundle>();

                    bundles.forEach(element => {
                        if (element.fields) {
                            var b = {
                                key: element.fields.category + "." + element.fields.key,
                                value: element.fields.text,
                            }
                            retBundles.push(b)
                        }

                    });

                    this._contentBundle[idx] = retBundles


                })

                resolve()
            })
        })
    }

    initOtherTreatment(): Promise<any> {
        return new Promise((resolve, reject) => {
            var promises = []
            BUNDLE_KEY.forEach(b => {
                promises.push(this.contentfulClient.getEntry('2lR7l6ixry2eAg8WU0KQU4', { 'locale': b, limit: 1000 }))
            })

            Promise.all(promises).then(responses => {
                var idxArray={}
                responses.forEach((res, idx) => {

                    var ot = []
                    
                    res.fields['otherTreatmentItem'].forEach((ele) => {
                        if (ele.fields) {
                            ele.fields['id'] = ele.sys.id
                            ele.fields['contentHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(ele.fields.content  , options))
                            ot.push(ele.fields)
                            if(idx ===1){
                                idxArray[ele.fields.id] =  ele.fields.title.replace(/ /g,"-")
                            }
                        }
                    })

                    this._otherTreatment[idx] = ot


                   
                })

                BUNDLE_KEY.forEach((b  , idx)=> {
                    this._otherTreatment[idx].forEach(element => {
                        element['seoKey'] = idxArray[element.id]
                        
                    });
                })


                // console.log(this._otherTreatment)
                return resolve()
            })

        })
    }


    changeLang(lang: string) {
        // this.cookieService.put("locale", lang,   {path:"/"});
        this.lang = lang
        var newRoute = this.router.url.replace('en-US', lang)
        newRoute = newRoute.replace('zh-Hant', lang)


        window.location.href = window.location.origin + newRoute

        //console.log(window.location.origin  + newRoute)
    }


    public getEntry(id: string, query?: any) {
        let promise
        if (this.isBrowser) {
            promise = this.contentfulClient.getEntry(id, query)
        } else {
            var url = environment.restUrl + "entry/" + id
            promise = this.http.get(url, query).toPromise()
        }
        return promise

    }

    sendEmail(emailContent: any): Promise<any> {
        return this.http.post(environment.emailUrl + 'mail', emailContent).toPromise()
    }





}