import { Component, OnInit } from '@angular/core';
import { ContentfulService } from './../contentful.service';
import { DomSanitizer } from '@angular/platform-browser';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import {  Meta ,Title} from '@angular/platform-browser';
import { bundle } from '../bundle';

@Component({
  selector: 'app-specialOffer',
  templateUrl: './specialOffer.component.html',
  styles: []
})
export class specialOfferComponent implements OnInit {

  constructor(private service:ContentfulService,private sanitizer: DomSanitizer, private metaService:Meta , private titleService:Title) { }

  get lang(){
    return this.service.lang
  }

  specialOffers:any
  ngOnInit() {
    this.specialOffers = this.service.getEntry("11uW0YG8uSkqmcu2cWsImK",{'locale':this.service.lang} ).then(res=>{
      var items = []
       res.fields['specialOfferItem'].forEach(element => {
        
        if(element.fields){
          element.fields['descriptionHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(element.fields.description))
          items.push(element.fields)
        }
        
      })
      this.service.hideLoader();
      // console.log(items)
      return items
    })

    var componentName = this.constructor.name;
    var seo = bundle[componentName]
    if(seo){
        this.titleService.setTitle(seo[this.service.lang].title)
        this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
        this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
    }


  }

  

}
