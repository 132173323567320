import { ContentfulService } from './../contentful.service';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import {  Meta ,Title} from '@angular/platform-browser';
import { bundle } from '../bundle';



@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styles: []
})
export class MediaComponent implements OnInit {

  constructor(private service:ContentfulService , private datePipe : DatePipe, private metaService:Meta , private titleService:Title ) { }

  mediaItems:any
  categories = ['All Categories']

  currentCate = 0;

  filteredMedias:any =[]


  ngOnInit() {
    this.mediaItems = this.service.getEntry("7g9PlWAT2EaKEmACYeyoAq",{'locale':this.service.lang}).then(res=>{
      var items = []

      var cat = []
       res.fields['mediaItem'].forEach(element => {
          // console.log(element)
          if(element.fields){
            items.push(element.fields)
            cat. push(  this.datePipe.transform(element.fields.postDate , 'yyyy') )
          }
      })
      
      this.categories = this.categories.concat( cat.filter((v, i, a) => a.indexOf(v) === i) ); 

      // console.log(items)

      this.filteredMedias = items.filter((v  ) => { 
        if(this.currentCate === 0){
          return true
        }else{
          return v.category === this.categories[this.currentCate] 
        }
      })
      this.service.hideLoader();
      // console.log(items)
      //console.log(this.categories)
      return items
    })

    var componentName = this.constructor.name;
    var seo = bundle[componentName]
    if(seo){
        this.titleService.setTitle(seo[this.service.lang].title)
        this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
        this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
    }

  }

  changeCategory(idx:number){
    this.currentCate = idx
    this.mediaItems.then(items =>{
      
      this.filteredMedias = items.filter((v  ) => { if(this.currentCate === 0){
        return true
      }else{
        return this.datePipe.transform(v.postDate , 'yyyy')=== this.categories[this.currentCate] 
      }} )
      // console.log(this.filteredMedias )    
    })
  }


goToMedia(media){
  window.open(media.link , "_media")
}
  
  
}
