import { Component, OnInit, HostListener ,Inject ,PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { ContentfulService, BUNDLE_HASH,BUNDLE_KEY } from '../contentful.service';


const BREAK_POINT: number = 991



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*'
      })),
      state('closed', style({
        height: 0

      })),
      transition('open => closed', [
        animate('0.2s')
      ]),
      transition('closed => open', [
        animate('0.2s')
      ]),
    ]),


  ],
})
@HostListener('window:resize', ['$event'])


export class HeaderComponent implements OnInit {

  isBrowser:boolean;
  smallNav:Boolean = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    //console.log("scroll in header")
    this.smallNav = (window.pageYOffset > 200)
  }
  
  get lang():string{
    return this.service.lang
  }

  get otherTreatment():any{
    return this.service.otherTreatment
  }
  constructor( private service: ContentfulService ,
    @Inject(PLATFORM_ID) private platformId) { 
    this.isBrowser = isPlatformBrowser(platformId);
  
  }
  navOpen: boolean = true
  treatmentOpen: boolean = false

  nav_overflow_hidden: boolean = false

  ngOnInit() {
    // console.log('caal head' , this.lang)
    if (this.isBrowser) { 
    this.updateNav(window.innerWidth)

    this.service.navEmitter.subscribe(value => {
      this.treatmentOpen = false
      if (window.innerWidth < BREAK_POINT) {
        this.navOpen = false
        this.nav_overflow_hidden = true
      }
    })
  }
  // console.log(this.otherTreatment)

  }

  onResize(event) {
    this.updateNav(event.target.innerWidth);
  }



  updateNav(w: number) {

    this.treatmentOpen = false
    if (w >= BREAK_POINT) {
      this.navOpen = true
      this.nav_overflow_hidden = false

    } else {
      this.navOpen = false
      this.nav_overflow_hidden = true
    }

  }

  displayChangeLang():string{
    if (this.service.lang ==='zh-Hant'){
      return 'ENG'
    }else{
      return '中'
    }
  }
  changeLang(){
    var langNum = BUNDLE_HASH[ this.service.lang ]
    langNum ^=1
    this.service.changeLang(BUNDLE_KEY[langNum])

    
  }
}
