
import { LoaderService } from './../loader.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ContentfulService } from '../contentful.service';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { bundle } from '../bundle';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})


export class HomeComponent implements OnInit, AfterViewInit {
  constructor(private service: ContentfulService, private sanitizer: DomSanitizer, private loaderService: LoaderService, private route: Router, private metaService: Meta, private titleService: Title) {
    // super(router ,activatedRouter , service )
  }


  get lang(): string {
    return this.service.lang
  }

  slideConfig = {
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 700,
  }

  videoConfig = {
    dots: false,
    arrows: false,
  }

  aboutSlideConfig = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 200,
  }

  aboutSlideActive = [true, false]


  imagesUrl = []

  kvImages: any

  videos: any
  currentVideoIdx = 0

  specialOffers: any


  @ViewChild('videoSlickModal') videoSlickModal: SlickCarouselComponent;

  @ViewChild('aboutSlickModal', { static: true }) aboutSlickModal: SlickCarouselComponent;



  ngOnInit() {

    this.kvImages = this.service.getEntry('5QluL66qKkKeSYUaUQ8kAO', { 'locale': this.service.lang }).then(res => {
      var items = []
      res.fields['homepageKVItem'].forEach(ele => {
        if (ele.fields) {
          items.push(ele.fields)
        }
      });
      // console.log(items)
      return items
    })

    this.videos = this.service.getEntry('236aLskwkkqeoAi8yeyKyA', { 'locale': this.service.lang }).then(res => {
      var items = []

      res.fields['homepageYoutubeItem'].forEach(ele => {
        if (ele.fields) {
          ele.fields['descriptionHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(ele.fields.description))
          ele.fields['videoLinkObj'] = this.sanitizer.bypassSecurityTrustResourceUrl(ele.fields.videoLink)
          items.push(ele.fields)
        }
      });

      return items
    })


    this.specialOffers = this.service.getEntry('11uW0YG8uSkqmcu2cWsImK', { 'locale': this.service.lang }).then(res => {
      let p = res.fields['specialOfferItem']
      var items = []
      p.forEach(ele => {
        if (ele.fields) {
          ele.fields['descriptionHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(ele.fields.description))
          items.push(ele.fields)
        }
      })
      // console.log(items)
      return items.slice(0, 2)
    })
    this.loaderService.hide()


    var componentName = this.constructor.name;
    var seo = bundle[componentName]
    if (seo) {
      this.titleService.setTitle(seo[this.lang].title)
      this.metaService.updateTag({ property: "og:title", content: seo[this.lang].title })
      this.metaService.updateTag({ property: "og:description", content: seo[this.lang].description })
      this.metaService.updateTag({ name: "description", content: seo[this.lang].description })
      this.metaService.updateTag({ name: "keyword", content: seo[this.lang].keyword })
    }



  }

  gotoPage(routeDesc: string) {
    this.route.navigate([this.lang+"/"+ routeDesc])
  }

  afterVideoChange(e) {
    // console.log(e)

    this.currentVideoIdx = e.currentSlide
  }

  initVideo(e) {
    //console.log(e)
    //console.log(this.videoSlickModal)


  }
  ngAfterViewInit() {
    //console.log(this.videoSlickModal.currentIndex)
  }


  changed = true
  afterAboutSlickChange(e) {
    // this.aboutSlideActive.forEach( (ele, idx, theArray) =>{ 
    //   theArray[idx]= false
    //   })

    this.changed = true
    // this.aboutSlideActive[e.currentSlide] = true
  }


  AboutSlickGoto(idx: number) {


    if (this.changed) {
      this.changed = false
      this.aboutSlideActive.forEach((ele, idx, theArray) => {
        theArray[idx] = false
      })

      this.aboutSlideActive[idx] = true
      this.aboutSlickModal.slickGoTo(idx)
    }
  }

  gotoKvLink(link:string){
    // console.log(link)
    this.route.navigate( [this.lang +"/"+ link])
  }


}
