import { ContentfulService } from './../contentful.service';
import { Component, OnInit } from '@angular/core';
import {  Meta ,Title} from '@angular/platform-browser';
import { bundle } from '../bundle';

@Component({
  selector: 'app-treatment-main',
  templateUrl: './treatment-main.component.html',
  styles: []
})
export class TreatmentMainComponent implements OnInit {

  constructor(private service:ContentfulService, private metaService:Meta , private titleService:Title) { }

  priceTableItems:any
  ngOnInit() {
    this.priceTableItems = this.service.getEntry('4WaBPokCGQGoy0QO2OiEci',{'locale':this.service.lang}).then(res =>{
      var items = []
      res.fields['priceTables'].forEach(element => {
        if(element.fields){
          items.push(element.fields)
        }
        
      });
      this.service.hideLoader();
      return items
    })

    var componentName = this.constructor.name;
    var seo = bundle[componentName]
    if(seo){
        this.titleService.setTitle(seo[this.service.lang].title)
        this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
        this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
        this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
    }

    
  }

}
