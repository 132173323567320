import { AnimationGuard } from './animation.guard';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BlogComponent } from './blog/blog.component';
import { specialOfferComponent } from './specialOffer/specialOffer.component';
import { TreatmentComponent } from './treatment/treatment.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CaseStudyComponent } from './case-study/case-study.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MediaComponent } from './media/media.component';
import { TreatmentMainComponent } from './treatment-main/treatment-main.component';
import { TreatmentOtherComponent } from './treatment-other/treatment-other.component';
import {BaseComponent} from './base.component'


const routes: Routes = [
  {path: '', canActivate: [AnimationGuard], redirectTo:'/zh-Hant/home', pathMatch :'full'},
  {path: ':lang', canActivate: [AnimationGuard], component:BaseComponent, children:
    [
      {path: 'home', canActivate: [AnimationGuard], component: HomeComponent},
      {path: 'about-us', canActivate: [AnimationGuard], component: AboutUsComponent},
      {path: 'case-study', canActivate: [AnimationGuard], component: CaseStudyComponent},
      {path: 'treatment/binding-methodology-and-common-problems-faced-by-mothers', canActivate: [AnimationGuard], component: TreatmentComponent},
      {path: 'treatment/remodel-binding', canActivate: [AnimationGuard], component: TreatmentMainComponent},
      {path: 'treatment/:id', canActivate: [AnimationGuard], component: TreatmentOtherComponent},
      {path: 'media', canActivate: [AnimationGuard], component: MediaComponent},
      {path: 'special-offer', canActivate: [AnimationGuard], component:specialOfferComponent},
      {path: 'blog', canActivate: [AnimationGuard], component:BlogComponent},
      {path: 'blog/:id', canActivate: [AnimationGuard], component:BlogComponent},
      {path: 'contact-us', canActivate: [AnimationGuard], component:ContactUsComponent},
      {path: '**' ,canActivate: [AnimationGuard],  redirectTo:'home' , pathMatch :'full'}
    ]

}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabled' })],
  exports: [RouterModule],
  providers: [AnimationGuard]
})
export class AppRoutingModule { }
