import { Component, OnInit, ViewChild, ElementRef ,Inject} from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentfulService } from '../contentful.service';
import { BLOCKS } from '@contentful/rich-text-types';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';import { DOCUMENT } from "@angular/common";
import { bundle } from '../bundle';

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) =>
      `<img src='http:${node.data.target.fields.file.url}' > `
  }
}


@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styles: []
})


export class BlogComponent implements OnInit {

  constructor(private router:Router, private activedRoute:ActivatedRoute ,
              private service: ContentfulService, private sanitizer: DomSanitizer ,
              @Inject(DOCUMENT) private _document: Document,
              private metaService:Meta , private titleService:Title) { }

  blogs: any =null;
  menuCollapsed = true;

  currentBlog: any
  @ViewChild('stickyMenu') sticky: ElementRef;


  ngOnInit() {

    if(this.blogs == null){
      this.blogs =this.loadBlogs()
    }


      this.activedRoute.params.subscribe(params =>{
        
        var id = params['id']

      
          this.blogs.then( items =>{

            if(id){
              this.currentBlog = items.find(o => o.blogTitle === id)
            }else{
              //this.currentBlog = items[0]
              this.router.navigate([ this.service.lang , 'blog', items[0].blogTitle] )
            }


          })
      })

      var componentName = this.constructor.name;
      var seo = bundle[componentName]
      if(seo){
          this.titleService.setTitle(seo[this.service.lang].title)
          this.metaService.updateTag({property:"og:title" , content:seo[this.service.lang].title})
          this.metaService.updateTag({property:"og:description" , content:seo[this.service.lang].description})
          this.metaService.updateTag({name:"description" , content:seo[this.service.lang].description})
          this.metaService.updateTag({name:"keyword" , content:seo[this.service.lang].keyword})
      }
  }


  loadBlogs(){

    
    return this.service.getEntry("4as8cics0MYYAiUcK6m2cE").then(res => {
      //  console.log(res.fields)

      var items = []
      res.fields['FHBlogItem'].forEach(element => {
        if(element.fields){
          element.fields['id']= element.sys.id
          element.fields['blogContentHtml'] = this.sanitizer.bypassSecurityTrustHtml(documentToHtmlString(element.fields.blogContent, options))
          items.push(element.fields)
        }

      });
     //  console.log(items)
     
      this.service.hideLoader();
      return items
    })
  }

  changeBlog(id:String){
    this.router.navigate([ this.service.lang , 'blog', id] )
    this.menuCollapsed = false
    this.service.hideLoader();
  }


  menuClick(collapsed?:boolean){
    if(collapsed){
      this.menuCollapsed=collapsed
      
    }else{
      this.menuCollapsed = !this.menuCollapsed
    }

    if(this.menuCollapsed){
      this._document.body.style.overflow ='auto'
    }else{
      this._document.body.style.overflow ='hidden'
    }
  }


}
